import * as Yup from "yup"

export default class TestimonialContent {
    id;
    title;
    language;
    image;
    value;
    function;

    static schema_add = Yup.object({
        title: Yup.string().required('CMS.Testimonials.Form.name').max(255, 'CMS.Testimonials.Form.name|255|max'),
        module_references: Yup.mixed().required('CMS.Testimonials.Form.module|select'),
        value: Yup.object({ text: Yup.string().required('CMS.Testimonials.Form.content') })
    });

    static fromJson(json) {
        let testimonialContent = new TestimonialContent();
        testimonialContent.id = json['id'];
        testimonialContent.title = json['title'];
        testimonialContent.language = json['language'];
        testimonialContent.value = json['value'] != null ? json['value'] : '{}';
        testimonialContent.image = json['image'];
        testimonialContent.function = json.function;
        return testimonialContent;
    }
}
