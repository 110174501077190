import * as XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import AttendeeHelper from './AttendeeHelper';
import CountryHelper from './CountryHelper';

class ClientHelper {
    static export = (data, settings) => {
        console.log(data, settings);
        let workbook = {
            Sheets: {
                'clients': XLSX.utils.json_to_sheet(data.clients.records.map(record => this.convertToExportRecord(record, data.clients, settings)))
            },
            SheetNames: ['clients']
        };

        if ('attendees' in data) {
            this.processAttendees(data, workbook, settings);
        }

        if (settings.showGenders) {
            this.processGendersTab(workbook, settings);
        }

        if ('website' in data) {
            this.processLanguagesTab(data.website, workbook, settings);
        }

        if (settings.showCountries) {
            this.processCountriesTab(workbook, settings);
        }

        let buffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        let blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        FileSaver.saveAs(blob, 'clients' + ('attendees' in data ? '-attendees' : '') + '.xlsx');
    }

    static processAttendees = (data, workbook, settings) => {
        workbook.Sheets.attendees = XLSX.utils.json_to_sheet(data.attendees.records.map(record => AttendeeHelper.convertToExportRecord(record, data.attendees, settings)))
        workbook.SheetNames.push('attendees');

        AttendeeHelper.additionalDynamicFields(workbook, data.attendees);

        return workbook;
    }

    static processGendersTab = (workbook, settings) => {
        workbook.Sheets.genders = XLSX.utils.json_to_sheet(['m', 'f', 'x'].map(gender => {
            return {
                id: gender,
                label: settings.intl.formatMessage({ id: 'CMS.Client.Content.Form.gender.' + gender })
            };
        }))
        workbook.SheetNames.push('genders');

        return workbook;
    }

    static processLanguagesTab = (website, workbook, settings) => {
        workbook.Sheets.languages = XLSX.utils.json_to_sheet(website.languages.map(language => {
            return {
                id: language.language,
                label: settings.intl.formatMessage({ id: 'CMS.User.Form.language.' + language.language })
            };
        }))
        workbook.SheetNames.push('languages');

        return workbook;
    }

    static processCountriesTab = (workbook, settings) => {
        workbook.Sheets.countries = XLSX.utils.json_to_sheet(CountryHelper.getCountries().map(country => {
            return {
                id: country.value,
                label: country.label
            };
        }))
        workbook.SheetNames.push('countries');

        return workbook;
    }

    static convertToExportRecord = (item, params, settings) => {
        // Set general data
        let record = {
            id: item.internal_id,
            email: item.email,
            first_name: item.first_name,
            last_name: item.last_name,
            language: item.language
        };

        if (params.settings.fields && Object.keys(params.settings.fields).length > 0) {
            if (params.settings.fields.phone) record.phone = item.phone;
            if (params.settings.fields.gender) record.gender = item.gender;
            if (params.settings.fields.national_insurance_number) record.national_insurance_number = item.national_insurance_number;
            if (params.settings.fields.birth_date) record.birth_date = item.birth_date;

            if (params.settings.fields.billing_address) {
                record.billing_street = item.billing_street;
                record.billing_number = item.billing_number;
                record.billing_box = item.billing_box;
                record.billing_postal = item.billing_postal;
                record.billing_city = item.billing_city;
                record.billing_country = item.billing_country;
            }
            if (params.settings.fields.delivery_address) {
                record.delivery_equal = item.delivery_equal;
                record.delivery_street = item.delivery_street;
                record.delivery_number = item.delivery_number;
                record.delivery_box = item.delivery_box;
                record.delivery_postal = item.delivery_postal;
                record.delivery_city = item.delivery_city;
                record.delivery_country = item.delivery_country;
            }

            if (params.settings.fields.bank_details) {
                record.iban = item.iban;
                record.bic = item.bic;
            }
        }

        if (params.settings.membership_webshop_discount_code) {
            record.webshop_discount_code = item.webshop_discount_code
        }

        if (params.settings.use_membership) {
            record.membership_enabled = item.membership.enabled ? 1 : 0;
            record.membership_status = settings.intl.formatMessage({ id: 'CMS.Client.overview.table.status.' + item.status });
            record.memship_expires = item.membership.expires?.format('YYYY-MM-DD');
            record.memship_paid = item.membership.paid?.format('YYYY-MM-DD');
        }

        for (let i = 1; i <= 2; i++) {
            if (('show_additional_gdpr_consent_' + i) in params.settings && params.settings['show_additional_gdpr_consent_' + i]) {
                record['gdpr_consent_' + i + '|' + params.settings['additional_gdpr_consent_' + i + '_text']] = item['consent_' + i] ? 1 : 0;
            }
        }

        return record;
    }

    static calculateBirthDateFromNationalInsuranceNumber = (nin) => {
        nin = nin.replace(/\.|_|-/g, '');
        let insuranceNumberValidation = this.validateNationalInsuranceNumber(nin);
        if (!insuranceNumberValidation) return;

        let bDate = (insuranceNumberValidation === 2 ? '20' : '19') + nin.substring(0, 6);
        bDate = moment(bDate, 'YYYYMMDD');

        return bDate.format('YYYY-MM-DD');
    }

    static validateNationalInsuranceNumber = (nin) => {
        if (!nin) return true;

        nin = nin.replace(/\.|_|-/g, '');

        // RR numbers need to be 11 chars long
        if (nin.length !== 11) return false;

        let checkDigit = parseInt(nin.substr(nin.length - 2, 2));
        let modFunction = function (nr) { return 97 - (nr % 97); };
        let nrToCheck = parseInt(nin.substr(0, 9));

        // first check without 2
        if (modFunction(nrToCheck) === checkDigit) return 1;

        // then check with 2 appended for y2k+ births
        nrToCheck = parseInt('2' + nin.substr(0, 9));

        return (modFunction(nrToCheck) === checkDigit) ? 2 : false;
    }
}

export default ClientHelper;