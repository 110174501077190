import axios from "axios";
import config from 'react-global-configuration';
import CustomHeaders from "./CustomHeaders";
import API from "../services/api";

export default class TestimonialRepository {
    static create(testimonial) {
        let data = {
            title: testimonial.title,
            value: JSON.stringify(testimonial.value),
            image: testimonial.image,
            function: testimonial.function,
            module_references: testimonial.module_references
        };
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/testimonial/create',
                data,
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => {
                    resolve(response)
                })
                .catch(error => reject(error));
        });
    }

    static delete(testimonial) {
        return new Promise((resolve, reject) => {
            axios.delete(
                config.get('api_url') + '/testimonial/delete?id=' + testimonial,
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => {
                    resolve(response)
                })
                .catch(error => reject(error));
        })
    }

    static getRows() {
        return new Promise((resolve, reject) => {
            axios.get(
                config.get('api_url') + '/testimonial/all',
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => {
                    resolve(response.data.testimonials)
                })
                .catch(error => reject(error.message));
        });
    }

    static tree() {
        return new Promise((resolve, reject) => {
            axios.get(
                config.get('api_url') + '/testimonial/all/tree',
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => {
                    resolve(response.data.testimonials)
                })
                .catch(error => reject(error.message))
        })
    }

    static updateTree(data) {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/testimonial/update/order',
                {
                    tree: data
                },
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    static get(id) {
        return new Promise((resolve, reject) => {
            axios.get(
                config.get('api_url') + '/testimonial/details/' + id,
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => resolve(response.data))
                .catch(error => reject(error.message))
        })
    }

    static getBySlug(slug) {
        return new Promise((resolve, reject) => {
            axios.get(
                config.get('api_url') + '/testimonial-content?slug=' + slug,
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => resolve(response.data))
                .catch(error => reject(error.message))
        })
    }

    static update(testimonialId, data) {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/testimonial/content/update',
                {
                    id: testimonialId,
                    title: data.title,
                    language: data.language,
                    value: JSON.stringify(data.value),
                    image: data.image,
                    function: data.function,
                    module_references: data.module_references
                },
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => resolve(response.data))
                .catch(error => reject(error))
        })
    }


    static getValidation(type) {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/testimonial/type/validation',
                {
                    type: type
                },
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => {
                    resolve(response)
                })
                .catch(error => reject(error.message));
        })
    }

    static updateTestimonial(id, language, settings, title) {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/testimonial/update',
                {
                    id: id,
                    language: language,
                    settings: { ...settings },
                    title: title
                },
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    }

    static toggle(id) {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + '/testimonial/toggle',
                {
                    id: id
                },
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => {
                    if (API.isSuccess(response)) {
                        resolve(response.data.active);
                    }
                })
                .catch(error => reject(error))
        })
    }
}
