import React from "react";
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from "react-intl";
import { DeleteModalComponent, StandardTableComponent, TableEditActionComponent, TableDeleteActionComponent } from "../../basics/table";
import Website from "../../../models/website/Website";
import { ListGroup, Tab } from "react-bootstrap";
import { Application, CountryHelper, CheckPermissions, GeneralSettingsHelper } from "../../../helpers";
import UserRolesEnum from "../../../enums/UserRolesEnum";
import { DynamicFieldValueRepository, EventRegistrationRepository, EventPeriodRepository, ModuleRepository, WebsiteRepository } from "../../../repository";
import FormToastComponent from "../../basics/FormToastComponent";
import FormattedMessageString from "../../basics/FormattedMessageString";
import Module from "../../../models/module/Module";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToolTipLink from "../../basics/ToolTipLink";
import LoaderComponent from "../../basics/layout/LoaderComponent";
import TableMoreActionComponent from "../../basics/table/TableMoreActionComponent";
import EventHelper from "../../../helpers/EventHelper";
import ModuleTypeEnum from "../../../enums/ModuleTypeEnum";

class PeriodOverviewTableComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            rows: this.props.rows,
            settings: Module.getSettings(),
            website: null,
            isLoading: true,
            language: Application.tableLanguage()
        };
    }

    componentDidMount() {
        WebsiteRepository.get(Website.get()).then(result => this.setState({ website: result, isLoading: false }));
    }

    columns = (index) => {
        return [
            {
                dataField: 'id',
                hidden: true
            },
            {
                dataField: 'title',
                text: <FormattedMessageString id="CMS.Event.period.overview.table.title" />,
                sort: true,
                isDummyField: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    let contentsA = rowA.contents[index];
                    let contentsB = rowB.contents[index];

                    if (order === 'asc')
                        return contentsA.title.toLowerCase() < contentsB.title.toLowerCase() ? -1 : 1;
                    else if (order === 'desc')
                        return contentsB.title.toLowerCase() > contentsA.title.toLowerCase() ? 1 : -1;
                },
                formatter: (cellContent, row) => row.contents[index]?.title
            },
            this.state.settings.show_start_date ? {
                dataField: 'date_start',
                text: <FormattedMessageString id="CMS.Event.period.overview.table.date_start" />,
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    let dateA = rowA.date_start;
                    let dateB = rowB.date_start;

                    return order === 'desc' ? Date.parse(dateB) - Date.parse(dateA) : Date.parse(dateA) - Date.parse(dateB)
                },
                isDummyField: true,
                formatter: (cellContent, row) => {
                    let content = row.contents[index];
                    /*let value = [];

                    if (row.date_start) {
                        value.push(row.date_start.format(this.state.settings.show_hours ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY'));
                    }

                    if (row.date_end) {
                        value.push(row.date_end.format(this.state.settings.show_hours ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY'));
                    }*/

                    return content.datetimeLabel;
                }
            } : {},
            {
                dataField: 'actions',
                isDummyField: true,
                text: <FormattedMessage id="CMS.Table.actions" />,
                hidden: !CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR),
                sort: false,
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { width: '10%' };
                },
                formatter: (cellContent, row) => {
                    let content = row.contents[index];

                    let moreActions = [];
                    if (this.state.settings.use_attendees) {
                        moreActions.push({
                            key: 'registrations', icon: 'id-card-clip', label: this.props.intl.formatMessage({ id: 'CMS.Event.period.overview.more_actions.registrations' }), callback: () => this.onRegistrationLinkClick(row)
                        });
                        moreActions.push({ key: 'export-registrations', icon: 'file-excel', label: this.props.intl.formatMessage({ id: 'CMS.Event.period.overview.more_actions.export_registrations' }), callback: () => this.exportRegistrations(row) });
                    }
                    moreActions.push({ key: 'duplicate', icon: 'copy', label: this.props.intl.formatMessage({ id: 'CMS.Event.period.overview.more_actions.duplicate' }), callback: () => this.duplicate(row, content.language) });

                    return <div className="actions">
                        {this.state.settings.use_attendees && (
                            <ToolTipLink to="#" className="link mr-1 px-1 link-auto" title="CMS.Event.overview.tooltip.nr_subscriptions" onClick={() => this.onRegistrationLinkClick(row)}>
                                <FontAwesomeIcon icon={['fa', 'id-card-clip']} className="mr-2" />
                                {row.nr_registrations ? row.nr_registrations : 0}
                            </ToolTipLink>
                        )}
                        {this.state.settings.use_attendees && (
                            <ToolTipLink title={row.registrationOpen() ? 'CMS.Event.period.overview.tooltip.registrations.open' : 'CMS.Event.period.overview.tooltip.registrations.closed'} onClick={() => this.quickUpdate(row, { event: row.event, action: 'registration_allowed' })}>
                                <FontAwesomeIcon className={row.registrationOpen() ? 'succcess' : 'danger'} icon={['far', 'fa-calendar']} />
                            </ToolTipLink>
                        )}
                        {CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR) && !GeneralSettingsHelper.is() && (
                            row.active ?
                                <Link onClick={(event) => this.quickUpdate(row, { event: row.event, action: 'active' })} className="active" to="#">
                                    <FontAwesomeIcon icon={['fas', 'check']} />
                                </Link>
                                :
                                <Link onClick={(event) => this.quickUpdate(row, { event: row.event, action: 'active' })} className="inactive" to="#">
                                    <FontAwesomeIcon icon={['fas', 'times']} />
                                </Link>
                        )}
                        {CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR) && !GeneralSettingsHelper.is() && (
                            <TableEditActionComponent id={row.id} custom={content !== undefined ? '/' + content.language : '/'} />
                        )}
                        {CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR) && !GeneralSettingsHelper.is() && (
                            <TableDeleteActionComponent row={row} parent={this} />
                        )}
                        {CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR) && !GeneralSettingsHelper.is() && (
                            <TableMoreActionComponent parent={this} id={row.id} items={moreActions} />
                        )}
                    </div>
                }
            }
        ];
    }

    updateData = () => EventPeriodRepository.all().then(response => this.props.parent.setState({ rows: response }));

    render() {
        if (this.state.isLoading) return <LoaderComponent />;
        if (this.state.reload) return <LoaderComponent />;

        return (
            <div className="tab-tables">
                <Tab.Container id="list-group-language-events"
                    defaultActiveKey={"#" + (this.state.website.languages[this.state.language] !== undefined ? this.state.website.languages[this.state.language].language : this.state.website.languages[0].language)}>
                    <ListGroup>
                        {this.state.website.languages.map((language, index) => (
                            <ListGroup.Item action href={"#" + language.language} key={index}
                                onClick={() => this.setState({ language: index }, () => Application.setTableLanguage(index))}>
                                {CountryHelper.getLanguages().find(({ value }) => value + "" === language.language + "").label}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                    <Tab.Content>
                        {this.state.website.languages.map((language, index) => (
                            <Tab.Pane eventKey={"#" + language.language} key={index}>
                                <div className="custom-data-table event">
                                    <StandardTableComponent keyField="id" data={this.state.rows} columns={this.columns(index)} search
                                        title={this.props.title_id} type={this.props.type}
                                        subRows={false}
                                        sort={{ dataField: 'date_start', order: 'desc' }}
                                    />
                                    <DeleteModalComponent parent={this} customDeleteRowFunction={this.delete} customCloseModalFunction={() => this.setState({ showDeleteModal: false, deleteRow: null })} />
                                </div>
                            </Tab.Pane>
                        ))}
                    </Tab.Content>
                </Tab.Container>
            </div>
        );
    }

    delete = (event, _row) => EventPeriodRepository.delete({ id: _row.id, event: this.props.match.params.event })
        .then(() => this.setState({
            rows: this.state.rows.filter((row) => _row.id !== row.id),
            showDeleteModal: false,
            deleteRow: null,
        }));

    quickUpdate = (_row, data) => EventPeriodRepository.quickUpdate({ ...{ id: _row.id }, ...data })
        .then(response => this.setState(curr => ({ ...curr, rows: [...this.state.rows].map(row => row.id === _row.id ? response : row), reload: true }), () => this.setState({ reload: false })))
        .catch(error => FormToastComponent.errorTrans('CMS.Client.overview.toggle.failed'));

    duplicate = (row, language) => this.props.history.push('duplicate/' + row.id + '/' + language);
    exportRegistrations = (row) => Promise.all([ModuleRepository.all(), EventRegistrationRepository.all(row.event, row.id), DynamicFieldValueRepository.all(1), DynamicFieldValueRepository.all(2), DynamicFieldValueRepository.all(3)])
        .then(results => {
            let moduleSettings = { event: this.state.settings, dynamicFields: { 1: results[2], 2: results[3], 3: results[4] } };
            results[0]
                .filter(module => [ModuleTypeEnum.CLIENT, ModuleTypeEnum.ATTENDEE].indexOf(module.module.type) > -1)
                .map(module => Module.fromJson(module.module))
                .forEach(module => moduleSettings[module.type] = Module.getSettings(module));

            let content = row.contents.filter(content => content.language === Application.language())[0];
            if (!content) content = row.contents[0];

            EventHelper.export(row, results[1], {
                settings: moduleSettings,
                intl: this.props.intl,
                language: Application.language(),
                filename: row.event_name + '_' + (row.date_start ? row.date_start.format('DD-MM-YYYY') : '') + '_' + content.title
            });
        });

    onRegistrationLinkClick = (row) => {
        Application.resetTablePageNumber();
        this.props.history.push('/module/events/' + Module.getId() + '/registrations/' + row.event + '/overview/' + row.id)
    }

}

export default withRouter(injectIntl(PeriodOverviewTableComponent));