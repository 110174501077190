import React from "react";
import { FormAlphaNumericError, FormDateMaxError, FormDateMinError, FormMaxError, FormRequiredError, FormNumberError, FormSelectError, FormEmailError, FormPhoneError, FormRequiredConditionalError, FormInvalidError } from "../../errors";
import FormLetterError from "../../errors/FormLetterError";

class ClientFormError extends React.Component {

    render() {
        let error = this.props.error.split('|');

        if (this.props.error.slice(-6) === "number" && this.props.error !== 'CMS.Client.Content.Form.national_insurance_number') {
            return <FormNumberError field_name={error[0]} />
        } else if (error[1] === 'min_date') {
            return <FormDateMinError field_name={error[0]} />
        } else if (error[1] === 'max_date') {
            return <FormDateMaxError field_name={error[0]} />
        } else if (this.props.error.slice(-3) === "max") {
            return <FormMaxError field_name={error[0]} max={error[1]} />
        } else if (this.props.error.slice(-5) === "match") {
            return <FormAlphaNumericError field_name={error[0]} />
        } else if (this.props.error.slice(-6) === "letter") {
            return <FormLetterError field_name={error[0]} />
        } else if (this.props.error.slice(-6) === 'select') {
            return <FormSelectError field_name={error[0]} />
        } else if (error[1] === 'invalidemail') {
            return <FormEmailError field_name={error[0]} />;
        } else if (error[1] === 'invalid') {
            return <FormInvalidError field_name={error[0]} />;
        } else if (this.props.error?.indexOf('|phone') > -1) {
            return <FormPhoneError field_name={error[0]} />;
        } else if (this.props.error?.indexOf('required.conditional') > -1) {
            return <FormRequiredConditionalError field1={error[0]} field2={error[1]} />;
        } else {
            return <FormRequiredError field_name={this.props.error} />
        }
    }
}

export default ClientFormError;