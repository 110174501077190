import React from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import DeleteModalComponent from "../basics/table/DeleteModalComponent";
import ClientFormError from './errors/ClientFormError';
import CustomTextArea from "../basics/CustomTextArea";
import { PriceHelper } from "../../helpers";

class ClientCustomSettingsFormComponent extends React.Component {
    constructor(props) {
        super(props);

        let { settings } = props.values;

        this._labels = {
            phone: 'show_telephone',
            gender: 'show_gender',
            birth_date: 'show_date_of_birth',
            national_insurance_number: 'show_national_register',
            billing_address: 'show_billing_address',
            delivery_address: 'show_shipping_address',
            bank_details: 'show_bank_details'
        };

        this.state = {
            fields: {
                phone: settings?.fields?.phone ? settings.fields.phone : false,
                gender: settings?.fields?.gender ? settings.fields.gender : false,
                birth_date: settings?.fields?.birth_date ? settings.fields.birth_date : false,
                national_insurance_number: settings?.fields?.national_insurance_number ? settings.fields.national_insurance_number : false,
                billing_address: settings?.fields?.billing_address ? settings.fields.billing_address : false,
                delivery_address: settings?.fields?.delivery_address ? settings.fields.delivery_address : false,
                bank_details: settings?.fields?.bank_details ? settings.fields.bank_details : false,
            },
            self_registration: settings.self_registration,
            use_membership: settings.use_membership,
            use_membership_duration: settings.use_membership_duration,
            membership_duration: settings.membership_duration,
            use_membership_start_date: settings.use_membership_start_date,
            membership_start_date: settings.membership_start_date,
            // send_out_renewal_membership: settings.send_out_renewal_membership,
            membership_price_currency: settings.membership_price_currency,
            membership_price: settings.membership_price ? PriceHelper.price(settings.membership_price) : null,
            membership_webshop_discount_code: settings.membership_webshop_discount_code,
            use_health_insurance_certificate: settings.use_health_insurance_certificate,
            use_attendees: settings.use_attendees,
            show_additional_gdpr_consent_1: settings.show_additional_gdpr_consent_1,
            additional_gdpr_consent_1_text: settings.additional_gdpr_consent_1_text,
            additional_gdpr_consent_1_required: settings.additional_gdpr_consent_1_required,
            show_additional_gdpr_consent_2: settings.show_additional_gdpr_consent_2,
            additional_gdpr_consent_2_text: settings.additional_gdpr_consent_2_text,
            additional_gdpr_consent_2_required: settings.additional_gdpr_consent_2_required,
            can_disable_national_insurance_number_field: settings.can_disable_national_insurance_number_field
        }

        this.originalValues = this.props.values;
    }

    onChange = (event, custom = null) => {
        let name = event.target.name.replace('settings.', '');
        if (event.target.type === "checkbox" && this.props.values.settings[name] && this.originalValues.settings[name] && this.state.deleteRow !== name) {
            DeleteModalComponent.openDeleteModal(event, name, this);
            this.setState({ currentEvent: { ...event } })
        } else {
            if (custom != null) {
                custom();
            }

            this.props.handleChange(event);
        }
    };

    changeConfirmModal = () => {
        this.state.currentEvent.currentTarget.click();

        this.setState({ showDeleteModal: false, deleteRow: null, currentEvent: null })
    }

    render() {
        return (
            <Form.Group className="custom-settings">
                <Row>
                    <Col xs={12}>
                        <FormattedMessage id={"CMS.Client.Form.fields"}>
                            {(value) => <Form.Label>{value}</Form.Label>}
                        </FormattedMessage>
                    </Col>


                    {Object.keys(this.state.fields).map(field => {
                        if (field === 'delivery_address' && !this.state.fields?.billing_address) return null;

                        return (
                            <Form.Group as={Col} xs={12} key={field}>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Check type="switch" className="mb-1" checked={this.state.fields[field]}
                                            label={this._labels[field]}
                                            id={'custom-switch-' + field} name={"settings.fields." + field}
                                            disabled={field === 'national_insurance_number' && !this.state.can_disable_national_insurance_number_field}
                                            onChange={event => this.onChange(event, () => {
                                                let fields = this.state.fields;
                                                fields[field] = !fields[field];
                                                if (field === 'billing_address' && !fields.billing_address) {
                                                    fields.delivery_address = false;
                                                    delete this.props.values.settings.fields.delivery_address;
                                                }

                                                this.setState({ fields: fields })
                                            })}
                                        />
                                        {field === 'national_insurance_number' && !this.props.values.settings.can_disable_national_insurance_number_field && (
                                            <FormattedMessage id="CMS.Client.Form.settings.info.national_insurance_number_field.disabled">
                                                {values => <p className="input-info text-warning">{values}</p>}
                                            </FormattedMessage>
                                        )}
                                        <FormattedMessage id={"CMS.Client.Form.settings.field." + field + '_info'}>
                                            {(values) => <p className="input-info">{values}</p>}
                                        </FormattedMessage>
                                    </Col>
                                </Row>
                            </Form.Group>
                        )
                    })}

                </Row>

                <Row>
                    <Col xs={12}>
                        <FormattedMessage id={"CMS.Client.Form.settings"}>
                            {(value) => <Form.Label>{value}</Form.Label>}
                        </FormattedMessage>
                    </Col>

                    {/* Self registration */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" label="self_registration" className="mb-1"
                                    name="settings.self_registration" id="custom-switch-self-registration"
                                    checked={this.state.self_registration}
                                    onChange={event => this.onChange(event, () => this.setState({ self_registration: !this.state.self_registration }, () => {
                                        this.props.values.settings.use_membership_start_date = false;
                                        delete this.props.values.settings.membership_start_date;

                                        this.setState({ use_membership_start_date: false, membership_start_date: null });
                                    }))}
                                />

                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Use membership */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" label="use_client_membership" className="mb-1" checked={this.state.use_membership}
                                    id="custom-switch-use-membership" name="settings.use_membership"
                                    onChange={(event) => this.onChange(event, () => this.setState({ use_membership: !this.state.use_membership, use_membership_duration: false, membership_duration: null, use_membership_start_date: false, membership_start_date: null }, () => {
                                        this.props.values.settings.use_membership_duration = false;
                                        delete this.props.values.settings.membership_duration;
                                        this.props.values.settings.use_membership_start_date = false;
                                        delete this.props.values.settings.membership_start_date;
                                    }))}
                                />

                            </Col>

                            {this.state.use_membership ?
                                <>
                                    <Form.Group className="subsettings">
                                        <Row>
                                            <Col md={12}>
                                                <Form.Check type="switch" label="client_membership_duration" className="mb-1 is-invalid"
                                                    id="custom-switch-use_membership_duration" name="settings.use_membership_duration"
                                                    checked={this.state.use_membership_duration}
                                                    onChange={event => this.onChange(event, () => this.setState({ use_membership_duration: !this.state.use_membership_duration }, () => {
                                                        delete this.props.values.settings.membership_duration;

                                                        if (this.state.use_membership_duration) {
                                                            this.setState({ use_membership_start_date: false, membership_start_date: null }, () => {
                                                                this.props.values.settings.use_membership_start_date = false;
                                                                delete this.props.values.settings.membership_start_date;
                                                            })
                                                        }
                                                    }))}
                                                />
                                                <Form.Control.Feedback type="invalid" className="mb-2">
                                                    {this.props.errors.settings?.use_membership_duration && (
                                                        <ClientFormError error={this.props.errors.settings.use_membership_duration} />
                                                    )}
                                                </Form.Control.Feedback>

                                            </Col>
                                            {this.state.use_membership_duration ?
                                                <Form.Group className="subsettings sub">
                                                    <Row>
                                                        <Col md={12}>
                                                            <InputGroup className="no-suffix mb-1 is-invalid">
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text id="inputGroupPrependEyecatcherWidth">months</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control type="text" name="settings.membership_duration"
                                                                    aria-describedby="membership_duration"
                                                                    onChange={this.onChange}
                                                                    isInvalid={this.props.errors.settings != null && this.props.errors.settings.membership_duration}
                                                                    value={this.props.values.settings.membership_duration}
                                                                />
                                                            </InputGroup>

                                                            {this.props.errors.settings?.membership_duration && (
                                                                <Form.Control.Feedback type="invalid" className="mb-2">
                                                                    <ClientFormError error={this.props.errors.settings.membership_duration} />
                                                                </Form.Control.Feedback>
                                                            )}

                                                            <FormattedMessage id="CMS.Client.Form.settings.info.membership_duration">
                                                                {values => <p className="input-info">{values}</p>}
                                                            </FormattedMessage>
                                                        </Col>
                                                    </Row>
                                                </Form.Group> :
                                                null}
                                            <Col md={12}>
                                                <FormattedMessage id="CMS.Client.Form.settings.info.use_membership_duration">
                                                    {values => <p className="input-info">{values}</p>}
                                                </FormattedMessage>
                                            </Col>

                                            <Col md={12}>
                                                <Form.Check type="switch" label="client_membership_start_date" className="mb-1 is-invalid" checked={this.state.use_membership_start_date}
                                                    name="settings.use_membership_start_date" id="custom-switch-use_membership_start_date"
                                                    onChange={event => this.onChange(event, () => this.setState({ use_membership_start_date: !this.state.use_membership_start_date }, () => {
                                                        delete this.props.values.settings.membership_start_date;

                                                        if (this.state.use_membership_start_date) {
                                                            this.setState({ use_membership_duration: false, membership_duration: null, self_registration: false }, () => {
                                                                delete this.props.values.settings.self_registration;
                                                                this.props.values.settings.use_membership_duration = false;
                                                                delete this.props.values.settings.membership_duration;
                                                            })
                                                        }
                                                    }))}
                                                />
                                                <Form.Control.Feedback type="invalid" className="mb-2">
                                                    {this.props.errors.settings?.use_membership_start_date && (
                                                        <ClientFormError error={this.props.errors.settings.use_membership_start_date} />
                                                    )}
                                                </Form.Control.Feedback>

                                            </Col>
                                            {this.state.use_membership_start_date ?
                                                <Form.Group className="subsettings sub">
                                                    <Row>
                                                        <Col md={12}>
                                                            <InputGroup className="large no-suffix mb-1 is-invalid">
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text id="inputGroupPrependEyecatcherWidth">date</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control type="date" name="settings.membership_start_date"
                                                                    aria-describedby="membership_start_date"
                                                                    onChange={this.onChange}
                                                                    isInvalid={this.props.errors.settings != null && this.props.errors.settings.membership_start_date}
                                                                    value={this.props.values.settings.membership_start_date}
                                                                />
                                                            </InputGroup>

                                                            {this.props.errors.settings != null && this.props.errors.settings.membership_start_date && (
                                                                <Form.Control.Feedback type="invalid" className="mb-2">
                                                                    <ClientFormError error={this.props.errors.settings.membership_start_date} />
                                                                </Form.Control.Feedback>
                                                            )}

                                                            <FormattedMessage id="CMS.Client.Form.settings.info.membership_start_date">
                                                                {values => <p className="input-info">{values}</p>}
                                                            </FormattedMessage>
                                                        </Col>
                                                    </Row>
                                                </Form.Group> :
                                                null}
                                            <Col md={12}>
                                                <FormattedMessage id="CMS.Client.Form.settings.info.use_membership_start_date">
                                                    {values => <p className="input-info">{values}</p>}
                                                </FormattedMessage>
                                            </Col>

                                            <Col md={12}>
                                                <InputGroup className="large mb-1 is-invalid">
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text id="inputGroupPrependEyecatcherWidth">client_membership_price</InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Form.Control as="select" name="settings.membership_price_currency" className="form-control-xs"
                                                        disabled aria-describedby="membership_price_currency"
                                                        onChange={this.onChange}
                                                        isInvalid={this.props.errors.settings != null && this.props.errors.settings.membership_price_currency}
                                                        value={this.props.values.settings.membership_price_currency}
                                                    >
                                                        {Object.keys(PriceHelper.getCurrencyList()).map(currency => (
                                                            <option value={currency} key={currency} dangerouslySetInnerHTML={{ __html: PriceHelper.getCurrencySign(currency) }} />
                                                        ))}
                                                    </Form.Control>
                                                    <Form.Control type="text" name="settings.membership_price" aria-describedby="membership_price"
                                                        onChange={this.onChange}
                                                        isInvalid={this.props.errors.settings != null && this.props.errors.settings.membership_price}
                                                        value={this.props.values.settings.membership_price}
                                                    />
                                                </InputGroup>

                                                {this.props.errors.settings != null && this.props.errors.settings.membership_price && (
                                                    <Form.Control.Feedback type="invalid" className="mb-2">
                                                        <ClientFormError error={this.props.errors.settings.membership_price} />
                                                    </Form.Control.Feedback>
                                                )}

                                                <FormattedMessage id="CMS.Client.Form.settings.info.membership_price">
                                                    {values => <p className="input-info">{values}</p>}
                                                </FormattedMessage>
                                            </Col>

                                            <Col md={12}>
                                                <Form.Check type="switch" label="use_client_membership_webshop_discount_code" className="mb-1" checked={this.state.membership_webshop_discount_code}
                                                    id="custom-switch-membership_webshop_discount_code" name="settings.membership_webshop_discount_code"
                                                    onChange={event => this.onChange(event, () => this.setState({ membership_webshop_discount_code: !this.state.membership_webshop_discount_code }))}
                                                />
                                                <FormattedMessage id="CMS.Client.Form.settings.info.membership_webshop_discount_code">
                                                    {values => <p className="input-info">{values}</p>}
                                                </FormattedMessage>
                                            </Col>

                                            <Col md={12}>
                                                <Form.Check type="switch" label="use_client_membership_health_insurance_certificate" className="mb-1" checked={this.state.use_health_insurance_certificate}
                                                    id="custom-switch-use_health_insurance_certificate" name="settings.use_health_insurance_certificate"
                                                    onChange={event => this.onChange(event, () => this.setState({ use_health_insurance_certificate: !this.state.use_health_insurance_certificate }))}
                                                />
                                                <FormattedMessage id="CMS.Client.Form.settings.info.use_health_insurance_certificate">
                                                    {values => <p className="input-info">{values}</p>}
                                                </FormattedMessage>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </>
                                : null}
                            <Col xs={12}>
                                <FormattedMessage id="CMS.Client.Form.settings.info.use_membership">
                                    {values => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Use attendees */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" label="use_attendees" className="mb-1" checked={this.state.use_attendees}
                                    id="custom-switch-use-attendees" name="settings.use_attendees" disabled={!this.props.values.settings.can_update_use_attendee_setting}
                                    onChange={event => this.onChange(event, () => this.setState({ use_attendees: !this.state.use_attendees }))}
                                />
                                {this.state.use_attendees && !this.props.values.settings.can_update_use_attendee_setting && (
                                    <FormattedMessage id="CMS.Client.Form.settings.info.use_attendees.disabled">
                                        {values => <p className="input-info warning">{values}</p>}
                                    </FormattedMessage>
                                )}
                                <FormattedMessage id="CMS.Client.Form.settings.info.use_attendees">
                                    {values => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show show_gdpr_consent_1 */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" label="show_additional_gdpr_consent_1" className="mb-1" checked={this.state.show_additional_gdpr_consent_1}
                                    id="custom-switch-show-gdpr-consent_1" name={"settings.show_additional_gdpr_consent_1"}
                                    onChange={event => this.onChange(event, () => this.setState({ show_additional_gdpr_consent_1: !this.state.show_additional_gdpr_consent_1 }, () => {
                                        delete this.props.values.settings.additional_gdpr_consent_1_text;
                                        delete this.props.values.settings.additional_gdpr_consent_1_required;
                                    }))}
                                />

                            </Col>

                            {this.state.show_additional_gdpr_consent_1 ?
                                <>
                                    <Form.Group className="subsettings">
                                        <Row>
                                            {!this.state.additional_gdpr_consent_1_text ?
                                                <Col md={12}>
                                                    <InputGroup className="xxl mb-1 is-invalud">
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text id="inputGroupPrependEyecatcherWidth">text</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <CustomTextArea
                                                            aria-describedby="additional_gdpr_consent_1_text"
                                                            name="settings.additional_gdpr_consent_1_text"
                                                            onChange={this.onChange}
                                                            isInvalid={this.props.errors.settings != null && this.props.errors.settings.additional_gdpr_consent_1_text}
                                                        >
                                                            {this.props.values.settings.additional_gdpr_consent_1_text}
                                                        </CustomTextArea>
                                                    </InputGroup>

                                                    {this.props.errors.settings != null && this.props.errors.settings.additional_gdpr_consent_1_text && (
                                                        <Form.Control.Feedback type="invalid" className="mb-2">
                                                            <ClientFormError error={this.props.errors.settings.additional_gdpr_consent_1_text} />
                                                        </Form.Control.Feedback>
                                                    )}

                                                    <FormattedMessage id="CMS.Client.Form.settings.info.additional_gdpr_consent_1_text">
                                                        {values => <p className="input-info">{values}</p>}
                                                    </FormattedMessage>
                                                </Col>
                                                : null}
                                            <Col lg={12}>
                                                <Form.Check type="switch" className="mb-1" label="additional_gdpr_consent_1_required" checked={this.state.additional_gdpr_consent_1_required}
                                                    id="custom-switch-additional_gdpr_consent_1_required" name={"settings.additional_gdpr_consent_1_required"}
                                                    onChange={event => this.onChange(event, () => this.setState({ additional_gdpr_consent_1_required: !this.state.additional_gdpr_consent_1_required }))}
                                                />
                                                <FormattedMessage id="CMS.Client.Form.settings.info.additional_gdpr_consent_1_required">
                                                    {values => <p className="input-info">{values}</p>}
                                                </FormattedMessage>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </>
                                : null}
                            <Col xs={12}>

                                <FormattedMessage id="CMS.Client.Form.settings.info.show_additional_gdpr_consent_1">
                                    {values => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show show_gdpr_consent_2 */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" label="show_additional_gdpr_consent_2" className="mb-1" checked={this.state.show_additional_gdpr_consent_2}
                                    id="custom-switch-show-gdpr-consent_2" name={"settings.show_additional_gdpr_consent_2"}
                                    onChange={event => this.onChange(event, () => this.setState({ show_additional_gdpr_consent_2: !this.state.show_additional_gdpr_consent_2 }, () => {
                                        delete this.props.values.settings.additional_gdpr_consent_2_text;
                                    }))}
                                />

                            </Col>

                            {this.state.show_additional_gdpr_consent_2 ?
                                <>
                                    <Form.Group className="subsettings">
                                        <Row>
                                            {!this.state.additional_gdpr_consent_2_text ?
                                                <Col md={12}>
                                                    <InputGroup className="xxl mb-1 is-invalid">
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text id="inputGroupPrependEyecatcherWidth">text</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <CustomTextArea
                                                            aria-describedby="additional_gdpr_consent_2_text"
                                                            name="settings.additional_gdpr_consent_2_text"
                                                            onChange={this.onChange}
                                                            isInvalid={this.props.errors.settings != null && this.props.errors.settings.additional_gdpr_consent_2_text}
                                                        >
                                                            {this.props.values.settings.additional_gdpr_consent_2_text}
                                                        </CustomTextArea>
                                                    </InputGroup>
                                                    {this.props.errors.settings != null && this.props.errors.settings.additional_gdpr_consent_2_text && (
                                                        <Form.Control.Feedback type="invalid" className="mb-2">
                                                            <ClientFormError error={this.props.errors.settings.additional_gdpr_consent_2_text} />
                                                        </Form.Control.Feedback>
                                                    )}

                                                    <FormattedMessage id="CMS.Client.Form.settings.info.additional_gdpr_consent_2_text">
                                                        {values => <p className="input-info">{values}</p>}
                                                    </FormattedMessage>
                                                </Col>
                                                : null}
                                            <Col lg={12}>
                                                <Form.Check type="switch" className="mb-1" label="additional_gdpr_consent_2_required" checked={this.state.additional_gdpr_consent_2_required}
                                                    id="custom-switch-additional_gdpr_consent_2_required" name={"settings.additional_gdpr_consent_2_required"}
                                                    onChange={event => this.onChange(event, () => this.setState({ additional_gdpr_consent_2_required: !this.state.additional_gdpr_consent_2_required }))}
                                                />
                                                <FormattedMessage id="CMS.Client.Form.settings.info.additional_gdpr_consent_2_required">
                                                    {values => <p className="input-info">{values}</p>}
                                                </FormattedMessage>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </>
                                : null}

                            <Col xs={12}>
                                <FormattedMessage id="CMS.Client.Form.settings.info.show_additional_gdpr_consent_2">
                                    {values => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>
                </Row>

                <DeleteModalComponent parent={this}
                    title={"CMS.Client.Form.setting.delete.title"} text={"CMS.Client.Form.setting.delete.text"}
                    buttons={{ confirm: { text: 'Default.confirm', icon: 'check' } }}
                    customDeleteRowFunction={this.changeConfirmModal}
                    customCloseModalFunction={() => this.setState({ showDeleteModal: false, deleteRow: null })}
                />
            </Form.Group>
        );
    }
}

export default ClientCustomSettingsFormComponent;